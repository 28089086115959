<template>
  <div class="pageContol formBOX">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">专家库</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{$route.query.expertId?'编辑':'新增'}}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="10rem">
              <el-form-item label="姓名" prop="expertName" class="form-item">
                <el-input v-model="ruleForm.expertName" size="small" placeholder="请输入姓名" />
              </el-form-item>
              <el-form-item label="手机号/微信号" prop="expertPhoneNumber" class="form-item">
                <el-input v-model="ruleForm.expertPhoneNumber" size="small" placeholder="请输入手机号/微信号" />
              </el-form-item>
              <el-form-item label="专家类型" prop="expertType" class="form-item">
                <el-select v-model="ruleForm.expertType" placeholder="请选择" size="small" clearable>
                  <el-option v-for="item in expertTypeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属单位" prop="workUnit" class="form-item">
                <el-input v-model="ruleForm.workUnit" size="small" placeholder="请输入所属单位" />
              </el-form-item>
              <el-form-item label="擅长领域" prop="expertExcel" class="form-item">
                <el-input type="textarea" maxlength="500" :autosize="{ minRows: 4, maxRows: 12 }" show-word-limit
                  v-model="ruleForm.expertExcel"></el-input>
              </el-form-item>
              <el-form-item label="个人简介" prop="expertIntroduction" class="form-item">
                <el-input type="textarea" maxlength="500" :autosize="{ minRows: 4, maxRows: 12 }" show-word-limit
                  v-model="ruleForm.expertIntroduction"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks" class="form-item">
                <el-input type="textarea" maxlength="500" :autosize="{ minRows: 4, maxRows: 12 }" show-word-limit
                  v-model="ruleForm.remarks"></el-input>
              </el-form-item>
              <el-form-item label="资料图片" prop="remarks" class="form-item dffi">
                <div v-for="(item, index) in ruleForm.informationPhotoList" :key="item.photo"
                  class="el-upload-list__item">
                  <img class="el-upload-list__item-thumbnail" :src="item.photoUrl" alt="">
                  <span class="el-upload-list__item-actions">
                    <span @click="handlePictureCardPreview(item)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span @click="handleDownload(item)">
                      <i class="el-icon-download"></i>
                    </span>
                    <span @click="handleRemove(index)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
                <el-upload action list-type="picture-card" accept="image/png, image/gif, image/jpg, image/jpeg"
                  :show-file-list="false" :on-change="handleAvatarSuccess2" :auto-upload="false">
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form>
            <div class="form-btn">
              <el-button class="bgc-bv" @click="$router.back()">取消</el-button>
              <el-button class="bgc-bv" @click="formSave()">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "expertDatabaseInfo",
  components: {
  },
  data () {
    return {
      ruleForm: {
        expertId: this.$route.query.expertId,
        // 姓名
        expertName: "",
        // 手机号/微信号
        expertPhoneNumber: "",
        // 专家类型
        expertType: "",
        // 所属单位
        workUnit: "",
        // 擅长领域
        expertExcel: "",
        // 个人简介
        expertIntroduction: "",
        // 备注
        remarks: "",
        informationPhotoList: []
      },
      rules: {
        expertName: [
          { required: true, message: "请输入姓名", trigger: "blur" }
        ],
        expertPhoneNumber: [
          { required: true, message: "请输入手机号/微信号", trigger: "blur" }
        ],
      },
      expertTypeList: [],//专家类型list

      dialogImageUrl: '',
      dialogVisible: false,
    };
  },
  created () {
    this.dataDictionary()
    if (this.$route.query.expertId) {
      this.getInfo(this.$route.query.expertId)
    }
  },
  computed: {},
  methods: {
    // 获取 - 数据字典
    dataDictionary () {
      // 考试科目
      const expertType = this.$setDictionary("EXPERT_TYPE", "list");
      for (const key in expertType) {
        this.expertTypeList.push({
          value: key,
          label: expertType[key],
        });
      }
    },
    getInfo (expertId) {
      this.$post('/biz/expert/info', {expertId})
      .then(res => {
        if (res.status == 0) {
         this.ruleForm = res.data
         this.ruleForm.informationPhotoList ||this.$set(this.ruleForm,'informationPhotoList',[])
        }
      })
    },
    // 上传图片
    handleAvatarSuccess2 (res) {
      if (this.ruleForm.informationPhotoList.length >= 9) {
        this.$message.error('资料图片最多上传9张！')
        return
      }
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      const jpg = extension === "jpg"||extension === "JPG";
      const png = extension === "png"||extension === "PNG";
      const jpeg = extension === "jpeg"||extension === "JPEG";
      if (!jpg &&!jpeg && !png) {
        this.$message.error("只能上传后缀是.jpg或.jpeg或.png的文件");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.informationPhotoList.push({
            photo: result.data.fileKey,
            photoUrl: result.data.fileURL
          })
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败"
            });
          }, 300);
        });
    },
    // 删除图片
    handleRemove (index) {
      this.ruleForm.informationPhotoList.splice(index, 1);
    },
    // 预览图片
    handlePictureCardPreview (item) {
      this.dialogImageUrl = item.photoUrl;
      this.dialogVisible = true;
    },
    // 下载图片
    handleDownload (item) {
      this.getBlob(item.photoUrl, (blob) => {
        this.saveAs(blob, '资料图片');
      });
    },
    getBlob (url, cb) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    saveAs (blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        // fix Firefox
        link.style.display = "none";
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    },
    // 保存
    formSave (formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          let url
          if (this.ruleForm.expertId) {
            url = '/biz/expert/update'
          } else {
            url = '/biz/expert/insert'
          }
          if(this.ruleForm.informationPhotoList.length>0){
            this.ruleForm.informationPhotos = this.ruleForm.informationPhotoList.map(e => e.photo).join(",")
          }else{
            this.ruleForm.informationPhotos = ''
          }
          this.$post(url, this.ruleForm)
            .then(ret => {
              if (ret.status == 0) {
                setTimeout(() => {
                  this.$message({
                    type: "success",
                    message: "保存成功"
                  });
                  this.$router.back();
                }, 300);
              }
            })
            .catch(() => {
              return;
            });
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.formBOX {
  /deep/ .el-form-item__content {
    width: 50%;

    .el-textarea__inner {
      resize: none;
      padding-bottom: 40px;
    }
  }

  /deep/ .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      width: 10rem;
    }
  }

  .el-select {
    width: 100% !important;
  }

  .dffi {
    .el-form-item__content {
      >div {
        width: 148px;
        vertical-align: top;
        display: inline-block;
      }
    }
  }

  .el-upload-list__item {
    vertical-align: top;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
    transition: all .5s cubic-bezier(.55, 0, .1, 1);
    font-size: 14px;
    color: #606266;
    line-height: 1.8;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .el-upload-list__item-actions:hover {
    opacity: 1;
  }

  .el-upload-list__item-actions span {
    display: none;
    display: inline-block;
    cursor: pointer;
  }
}
</style>
